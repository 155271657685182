div.list-container {
  @apply flex flex-col;
}

ul.dump-list {
  @apply flex flex-col rounded-md;
}

li.dump-list-row {
  @apply flex flex-1 md:text-lg w-full first:rounded-t-xl last:rounded-b-xl border-b-1 border-dark/10 dark:border-bright/10 last:border-none text-dark dark:text-white bg-white dark:bg-white/5 transition-all duration-100;
}

li.dump-list-row a {
  @apply flex flex-1 w-full py-4 pl-6 pr-4;
}
