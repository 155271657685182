.mentions {
  @apply h-full;
}

.mentions__control {
  @apply text-base text-dark dark:text-bright mb-5;
}

.mentions__input {
  @apply outline-none md:ml-1 focus-visible:outline-none;
}

.mentions__suggestions {
  @apply rounded-2xl dark:bg-paperdark ml-2 mt-40;
}

.mentions__suggestions__item--focused {
  @apply bg-cyan/25 dark:bg-dark/50;
}