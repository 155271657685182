li.dump-list-row {
  @apply flex flex-1 md:text-lg w-full first:rounded-t-xl border-b-1 last:border-none last:rounded-b-xl border-dark/10 dark:border-bright/10 text-dark dark:text-white bg-white dark:bg-white/5;
}

li.dump-list-row a {
  @apply flex flex-1 w-full py-2 pl-6 pr-4;
}

.row-link-container {
  @apply flex flex-1 items-center justify-between gap-2;
}

.dump-text {
  @apply flex h-full whitespace-pre-line truncate max-h-28 sm:max-h-32 md:max-h-64;
}

li.dump-list-row a,
li.dump-list-row a.active {
  @apply focus-within:outline-0 focus-within:ring-0 focus-within:outline-none active:outline-none active:ring-0 border-0;
}
